import styles from './App.module.css';

// import logo from './logo.svg';
import { Avatar, Button, Divider, Flex, Layout, Space, Tooltip, Typography, } from 'antd';
import androidLogo from './asset/android.svg';
import iosLogo from './asset/ios.svg';
import windowsLogo from './asset/windows.svg';
import macosLogo from './asset/macos.svg';
import logo from './asset/logo.svg';

import {
  BrowserRouter, Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import Repository from "@/repository";
import './App.css';
import { ReactNode, useEffect, useState } from 'react';
import { useAtomValue } from 'jotai';
import { repoAtom } from './context/atom';
import { DownloadOutlined } from '@ant-design/icons';

const APP_ID = 'b0a7e8c7-f5dd-4445-b727-2d066a850946';

export default () => {
  const basename = document
    ?.querySelector('meta[name="base"]')
    ?.getAttribute("content") ?? process.env.REACT_APP_BASE_PATH;
  return <BrowserRouter basename={basename}>
    <Layout style={{minHeight:'100vh'}}>
      <Layout.Header style={{background:'transparent', color:'white', padding: '10px 20px'}}>
        <Flex align='center' gap='small'>
          <Avatar src={logo} />
          <Typography.Text style={{fontSize: 20}}>PasteMate</Typography.Text>
        </Flex>
      </Layout.Header>
      <Layout.Content>
        <Routes>
          <Route path="/" element={<MainView />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Layout.Content>
      <Layout.Footer style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
       <JoinButton/> 
      </Layout.Footer>
    </Layout>
  </BrowserRouter>
};

const JoinButton = () => {
  return <Typography.Text type='secondary'>
    做最好用的复制粘贴软件
    <Divider  type='vertical' />
    <Typography.Link target='_blank' href='https://www.yuque.com/xingshujun-0bvzz/movbus/wdnrwprwvzeizlw0?singleDoc#SUhLl'>
      使用说明
    </Typography.Link>
    <Divider  type='vertical' />
    <Tooltip title={<Avatar shape='square' size={120} src='wechat.png' />}>
      <Typography.Link> 加入微信群反馈意见 </Typography.Link>
    </Tooltip>
  </Typography.Text>
}

const checkArm = (defaultArm?: boolean) => {
  const w = document.createElement("canvas").getContext("webgl");
  const d = w?.getExtension('WEBGL_debug_renderer_info');
  const g = d && w?.getParameter(d.UNMASKED_RENDERER_WEBGL) || "";
  console.log(w?.getSupportedExtensions()?.indexOf("WEBGL_compressed_texture_s3tc_srgb"));
  return g.match(/Apple/) && (!g.match(/Apple GPU/) || defaultArm || w?.getSupportedExtensions()?.indexOf("WEBGL_compressed_texture_s3tc_srgb") == -1);
}

const MainView = (props: {}) => {
  const [os, setOs] = useState<{os: string, version: string, arm: boolean}>();
  const [statistic, setStatistic] = useState<{clientCount: number}>();
  const repo = useAtomValue(repoAtom);
  useEffect(() => {
    repo.checkUpdateStatistic(APP_ID).then(setStatistic).catch();
    const agentData = (navigator as any).userAgentData;
    if (agentData) {
      agentData.getHighEntropyValues(['platform', 'platformVersion'])
        .then((v: { platform: string, platformVersion: string }) => {
          let arm = false;
          if (v.platform == 'macOS' && v.platformVersion >= '10.15') arm = checkArm();
          setOs({
            os: v.platform,
            version: v.platformVersion,
            arm: arm
          });
        });
    } else if (navigator.userAgent) {
      const platform = /windows/i.test(navigator.userAgent) ? "Windows"
        : /iphone/i.test(navigator.userAgent) ? 'iOS'
          : /ipad/i.test(navigator.userAgent) ? 'iOS'
            : /macintosh/i.test(navigator.userAgent) ? "macOS" : '';
      setOs({
        os: platform,
        version: '',
        arm: platform == 'macOS' && checkArm(true)
      });
    }
  }, []);
  return <Flex vertical align='center' style={{ paddingTop: 40, paddingLeft: 20, paddingRight: 20 }}>
    <Typography.Title style={{ fontSize: 50 }}>最好用的复制粘贴软件 PasteMate</Typography.Title>
    <Typography.Text type='secondary'>效率提升 | 免费使用 | 轻量工具{statistic ? <Typography.Text type='success' style={{ fontSize: 12, fontWeight: 300, marginLeft: 10}}>
      <DownloadOutlined style={{marginRight: 4}}/>
      {new Intl.NumberFormat('en').format(statistic.clientCount,)}
    </Typography.Text> : null}
    </Typography.Text>
    <Space direction='vertical' align='center' size={0} style={{ marginTop: 30, marginBottom: 100 }}>
      <Typography.Title>客户端 </Typography.Title>
      <Typography.Text type='secondary' style={{ fontSize: 22 }}>同时支持 Windows、 macOS、Android、 iOS 版本 </Typography.Text>
    </Space>
    <Flex align='center' style={{ maxWidth: 800, width: '100%' }} wrap gap={10} justify='space-between'>
      <DownloadWindow repo={repo}/>
      <DownloadMacos repo={repo} arm={os?.arm} />
      <DownloadButton image={androidLogo}>即将推出</DownloadButton>
      <DownloadButton image={iosLogo}>即将推出</DownloadButton>
    </Flex>
  </Flex>;
}

const DownloadWindow = (props: {repo: Repository}) => {
  const {repo} = props;
  const [showInfo, setShowInfo] = useState(false);
  const [version, setVersion] = useState<string>()
  useEffect(() => {
    if (showInfo && !version) repo.listAppVersion(APP_ID, { os: 'windows' })
       .then(v => setVersion(v.length ? v[0].name : ''));
  }, [showInfo]);
  return <DownloadButton image={windowsLogo}
    url='https://dev.cqdune.com:10807/job/effciency-app-win/lastSuccessfulBuild/artifact/build/windows/x64/runner/PasteMateInstaller.exe'
    otherLink={{
      name: '下载免安装版',
      url: 'https://dev.cqdune.com:10807/job/effciency-app-win/lastSuccessfulBuild/artifact/build/windows/x64/runner/release_win_x64.tar.gz'
    }} > 
    <Tooltip title={`版本: ${version ?? '-'}`} onOpenChange={setShowInfo}> 下载 Windows 版 </Tooltip>
    </DownloadButton>
}

const DownloadMacos = (props: { repo: Repository, arm?: boolean }) => {
  const { repo, arm } = props;
  const [showInfo, setShowInfo] = useState(false);
  const [version, setVersion] = useState<string>()
  useEffect(() => {
    if (showInfo && !version) repo.listAppVersion(APP_ID, { os: 'macOS', architecture: arm ? 'arm64' : 'x64' })
       .then(v => setVersion(v.length ? v[0].name : ''));
  }, [showInfo]);
  const x64 = 'https://dev.cqdune.com:10807/job/effciency-app-macos/lastSuccessfulBuild/artifact/build/macos/Build/Products/Release/PasteMate.dmg';
  const arm64 = 'PasteMateSlicon.dmg';
  return <DownloadButton image={macosLogo}
    url={arm ? arm64 : x64}
    otherLink={{
      name: arm ? '下载Intel芯片版' : '下载Apple芯片版',
      url: arm ? x64 : arm64
    }} >
    <Tooltip title={`版本: ${version ?? '-'}`} onOpenChange={setShowInfo}> 下载 macOS 版 </Tooltip>
  </DownloadButton>
}

const DownloadButton = (props: {image: string, children?: ReactNode, 
  url?: string, 
  loading?: boolean,
  otherLink?: {name: string, url: string}
}) => {
  const {image, children, url, loading, otherLink} = props;
  return <Space direction='vertical' align='center' size='large' style={{minWidth: 140}}>
    <Avatar size={80} shape='square' src={image} />
    <Flex vertical align='center' gap='small'>
      <Button style={{ minWidth: 80, background: 'transparent' }}
        target='_blank'
        href={url}
        loading={loading}
        disabled={url == undefined}>{children}</Button>
      <Typography.Link
        style={{ fontSize: 12, visibility: otherLink?.name ? undefined : 'hidden'}}
        target='_blank'
        href={otherLink?.url}>{otherLink ? otherLink.name : '-'}</Typography.Link>
    </Flex>
  </Space>
}